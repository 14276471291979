import React from "react";
import styles from '..//FooterN/footer.module.css'
import { NavLink } from "react-router-dom";

export default function FooterN() {
    return (
        <div className={styles["footer-menu"]}>
            <div className={styles["footer-left-side"]}>
                <div className={styles["icon-image"]}>
                    <img src="../../images/scanonn.png" alt=""/>
                </div>
                <div className={styles["content-product"]}> 
                    <p>We growing up your business</p> <p>with our latest products...</p>
                </div>
                <div className={styles["date-info"]}>
                    <p>Scanonn, 2024.</p>
                    <p>Follow Us On</p>
                    <p className={styles["social-icons"]}>
                        <div><a href="https://www.instagram.com/scanonn.qr/" target='_blank' rel="noreferrer"><i className="fa fa-instagram"></i></a></div>
                        <div><a href="https://www.linkedin.com/in/scan-onn-9860812b8" target='_blank' rel="noreferrer"><i className="fa fa-linkedin-square"></i></a></div>
                        <div><a href="https://www.youtube.com/@scanonn" target='_blank' rel="noreferrer"><i className="fa fa-youtube-play"></i></a></div>
                    </p>
                </div>
                <div className={styles["copyright"]}>
                    Copyright 2024 ScanOnn All right reserved.
                </div>
            </div>
            <div className={styles["footer-right-side"]}>
                <div className={styles["right-content"]}>
                    <NavLink to={'/about'}><span>About Us</span></NavLink>
                    <NavLink to={'/privacy-policy'}><span>Privacy Policy</span></NavLink>
                    <NavLink to={'/term-and-condition'}><span>Term and Conditions</span></NavLink>
                    <NavLink to={'/cancellation-policy'}><span>CancellationPolicy</span></NavLink>
                    <span>FAQ’s</span>
                </div>
                <p className={styles["have-account"]}>Have questions? Say hello!</p>
                <p className={styles["support-email"]}>support@scanonn.com</p>
            </div>
        </div>
    );
}
