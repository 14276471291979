import React from 'react';
import Navbar from "../Components/Navbar/Navbar";
import styles from './privacy-policy.module.css';

export default function PrivacyPolicy(){
    return (<>
          <Navbar />
        <section>
            <div className='container'>
                <div className={styles['privacy-policy']}>
                    <h2>Privacy Policy</h2>
                    <p><b>Effective Date: 18-05-2024</b></p>
                    <h3 className={styles['heading']}>1. Introduction</h3>
                    <p>Welcome to Scanonn! We are committed to protecting your privacy and ensuring you have a positive experience on our website and in using our products and services. This Privacy Policy outlines how we collect, use, and safeguard your information.</p>
                    <h3 className={styles['heading']}>2. Information We Collect</h3>
                    <ul>
                        <li>Personal Information: When you register on our website, we collect personal information such as your name, email address, and contact details.</li>
                        <li>Usage Data: We collect information about how you use our website, including your IP address, browser type, and operating system.</li>
                        <li>QR Code Data: When you use our services to generate QR codes, we may collect the data encoded in the QR codes.</li>
                    </ul>
                    <h3 className={styles['heading']}>3. How We Use Your Information</h3>
                    <ul>
                        <li>To Provide Services: We use your information to provide and improve our QR code generation services.</li>
                        <li>Communication: We use your email address to communicate with you about your account and our services.</li>
                        <li>Analytics: We use usage data to analyze trends and improve our website’s performance.</li>
                    </ul>
                    <h3 className={styles['heading']}>4. Information Sharing and Disclosure</h3>
                    <ul>
                        <li>Third-Party Service Providers: We may share your information with third-party service providers who assist us in operating our website and providing our services.</li>
                        <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                    </ul>
                    <h3 className={styles['heading']}>5. Data Security</h3>
                    <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>
                    <h3 className={styles['heading']}>6. Your Rights</h3>
                    <ul>
                        <li>Access and Correction: You have the right to access and correct your personal information.</li>
                        <li>Deletion: You can request the deletion of your personal information, and we will comply, unless we are legally required to retain it.</li>
                        <li>Opt-Out: You can opt-out of receiving marketing communications from us at any time by following the unsubscribe instructions in our emails.</li>
                    </ul>
                    <h3 className={styles['heading']}>7. Cookies</h3>
                    <p>We use cookies to enhance your experience on our website. You can control the use of cookies at the individual browser level.</p>
                    <h3 className={styles['heading']}>8. Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>
                    <h3 className={styles['heading']}>9. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:support@scanonn.com">support@scanonn.com</a></p>
                </div>
            </div>
        </section>
    </>)
}
