import Navbar from "./Components/Navbar/Navbar";
import FooterN from "./Components/FooterN/Footer";
import styles from './contact-us.module.css';

export default function ContactUs() {
    return (<>
        <Navbar />
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>“Unlock the Power of Visual Communication, Contact Us Today!”</h1>
                    </div>
                    <div>
                        <img src="../images/contact-banner.png" alt="banner" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['address-container']}>
                    <h2 className={styles['address-heading']}>Please fill free to reach us out!</h2>
                    <div className={styles['address-box']}>
                        <p className={styles['address']}>
                            <span>Germany</span>
                            Lothringer Straße 12, Nuremberg, 90461, Bayren Germany
                            {/* +49 17669596643 */}
                        </p>
                        <p className={styles['address']}>
                            <span>India</span>
                            Plot No. 97, Prem Puri, Sector 32, Gurugram, Haryana 122001
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <h2 className={styles['heading']}>Let’s Start a Conversation</h2>
                <div className={styles['contact-us']}>
                    <div>
                        <img src="../images/contact.png" alt="contact" width="100%" />
                        <p>We value your feedback, inquiries, and collaboration opportunities. Whether you have a question, suggestion, or simply want to reach out, this is the place to connect with us. Our dedicated team is here to assist you and ensure that your experience with us is smooth and satisfactory.</p>
                    </div>
                    <div className={styles['data-field']}>
                        <form>
                            <input type="text" placeholder="Full name" required />
                            <input type="text" placeholder="Email email" required />
                            <input type="text" placeholder="Mobile number" required />
                            <input type="text" placeholder="Organisation" required />
                            <input type="text" placeholder="Select service" required />
                            <textarea className="form-control" placeholder="Comment" required></textarea>
                            <br />
                            <button type="submit" className="btn btn-primary mt-3" >Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <FooterN />
    </>)
}