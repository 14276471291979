// src/components/Navbar.js
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.css";

export default function CustomNavbar() {
  const [opendropdown, setopendropdown] = useState(false);
  const [subdropdown, setsubdropdown] = useState("qr");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  const [mobilenavbar, setmobilenavbar] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isNfcVisible, setNfcIsVisible] = useState(false);

  const toggleDiv = () => {
    setIsVisible(!isVisible);
  };
  const toggleNfcDiv = () => {
    setNfcIsVisible(!isNfcVisible); 
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <header className={styles.NAVBAR}>
        <div className={styles.header}>
          <div>
            <img className={styles.logo} src="./images/Logo-B2lqG4nz.svg" alt="" />
          </div>
          <div
            className={styles.navbar}
            style={{ display: isMobileView ? "none" : "flex" }}
          >
            <NavLink
              to={"/"}
              className={({ isActive }) => (isActive ? "abc" : "")}
            >
              <h5>Home</h5>
            </NavLink>

            <NavLink
              to={"/about"}
              className={({ isActive }) => (isActive ? "abc" : "")}
            >
              <h5>About</h5>
            </NavLink>

            <div
              className={styles.dropdown}
              onMouseEnter={() => setopendropdown(true)}
              onMouseLeave={() => setopendropdown(false)}
            >
              <h5>Our Solutions</h5>
              {opendropdown ? (
                <div className={styles.maincontainer}>
                  <div className={styles.mainvector}>
                    <img src="./images/dropdownvectormain.png" alt="" />
                  </div>
                  <div className={styles.subcontainer}>
                    <div className={styles.menudiv}>
                      <a
                        href="https://qrcode.scanonn.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={
                          subdropdown === "qr" ? styles.QRdiv : styles.QRdiv1
                        }
                        onMouseEnter={() => setsubdropdown("qr")}
                      >
                        QR-Code
                        {subdropdown === "qr" ? (
                          <img
                            className={styles.subdropdown}
                            src="./images/dropdownvectorsub.png"
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </a>

                      <a
                        href="https://nfc.scanonn.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={
                          subdropdown === "nfc" ? styles.NFCdiv1 : styles.NFCdiv
                        }
                        onMouseEnter={() => setsubdropdown("nfc")}
                        onMouseLeave={() => setsubdropdown("qr")}
                      >
                        NFC-Card
                        {subdropdown === "nfc" ? (
                          <img
                            className={styles.subdropdown}
                            src="./images/dropdownvectorsub.png"
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                    {subdropdown === "qr" ? (
                      <div className={styles.contentdiv}>
                        <div className={styles.services}>
                          <img src="./images/website.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank"rel="noreferrer"><>
                            <h3>Website</h3>
                            <p>Open a URL</p></>
                          </a>
                          
                          </div>
                        </div>
                        <div className={styles.services}>
                          <img src="./images/Media.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank"rel="noreferrer"><>
                            <h3>Media</h3>
                            <p>Image, audio and video</p></>
                            </a>
                          </div>
                        </div>
                        <div className={styles.services}>
                          <img src="./images/whatsapp.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank"rel="noreferrer">
                          <>
                            <h3>WhatsApp</h3>
                            <p>Show a Whatsapp</p>
                          </>
                          </a>
                          </div>
                        </div>
                        <div className={styles.services}>
                          <img src="./images/vcard.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank"rel="noreferrer">
                          <>
                            <h3>Vcard-Plus</h3>
                            <p>Share contact details</p>
                          </>
                          </a>
                          </div>
                        </div>
                        <div className={styles.services}>
                          <img src="./images/pdf.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank"rel="noreferrer">
                          <>
                            <h3>PDF</h3>
                            <p>Open a Pdf</p>
                          </>
                          </a>
                          </div>
                        </div>
                        <div className={styles.services}>
                          <img src="./images/text.svg" alt="" />
                          <div className={styles.servicedetail}>
                          <a href="https://qrcode.scanonn.com" target="_blank" rel="noreferrer">
                          <>
                            <h3>Text</h3>
                            <p>Open a Text</p>
                          </>
                          </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.nfccontent}
                        onMouseEnter={() => setsubdropdown("nfc")}
                        onMouseLeave={() => setsubdropdown("qr")}
                      >
                        <img src="./images/NFC-Upcomming 1.svg" alt="" />
                        <h3>NFC-ScanOnn</h3>
                        <p>Fast and Secure NFC Scanning Solutions</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <NavLink to={"/contact-us"}>
              <button>Contact</button>
            </NavLink>
          </div>
          
          <div
            className={styles.hamburgermenu}
            onClick={() => {
              setmobilenavbar(!mobilenavbar);
            }}
          >
            ☰
          </div>

          <div
            className={
              mobilenavbar ? styles.mobilenavbaractive : styles.mobilenavbar
            }
          >
            <div className={styles.closeimg}>
              <img
                onClick={() => {
                  setmobilenavbar(!mobilenavbar);
                }}
                src="./images/close.svg"
                alt=""
              />
            </div>
            <div className={styles.navlinks}>
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <h5>Home</h5>
              </NavLink>
              <NavLink
                to={"/about"}
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                <h5>About</h5>
              </NavLink>

              <div className={styles.solution}>
                <div className={styles.oursolution}>
                  <h5>Our Solutions</h5>
                  <img src="./images/arrow-down.svg" alt="" />
                </div>
                <div className={styles.servicesolution}>
                  <div
                    className={styles.servicesolutionheading}
                    onClick={toggleDiv}
                  >
                    <h6>QR-Code</h6>
                    <img
                      className={
                        isVisible ? styles.activeimg : styles.defaultimg
                      }
                      src="./images/arrow-down.svg"
                      alt=""
                    />
                  </div>
                  {isVisible ? (
                    <div className={styles.ourservices}>
                      <div className={styles.service}>
                        <img src="./images/website.svg" alt="" />
                        <h6>Website</h6>
                      </div>
                      <div className={styles.service}>
                        <img src="./images/Media.svg" alt="" />
                        <h6>Media</h6>
                      </div>
                      <div className={styles.service}>
                        <img src="./images/vcard.svg" alt="" />
                        <h6>V-Card</h6>
                      </div>
                      <div className={styles.service}>
                        <img src="./images/whatsapp.svg" alt="" />
                        <h6>Whatsapp</h6>
                      </div>
                      <div className={styles.service}>
                        <img src="./images/text.svg" alt="" />
                        <h6>Text</h6>
                      </div>
                      <div className={styles.service}>
                        <img src="./images/pdf.svg" alt="" />
                        <h6>PDF</h6>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <hr />
                </div>

                <div className={styles.servicesolution}>
                  <div
                    className={styles.servicesolutionheading}
                    onClick={toggleNfcDiv}
                  >
                    <h6>NFC-Card</h6>
                    <img
                      className={
                        isNfcVisible ? styles.activeimg : styles.defaultimg
                      }
                      src="./images/arrow-down.svg"
                      alt=""
                    />
                  </div>
                  {isNfcVisible ? (
                    <div className={styles.ourservices}>
                      <img
                        className={styles.nfcimg}
                        src="./images/NFC-Upcomming 1.svg"
                        alt=""
                      />
                      <h5>NFC-ScanOnn</h5>
                      <p>Fast and Secure NFC Scanning Solutions</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button>Contact</button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
