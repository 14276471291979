import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar from "./Components/Navbar/Navbar";
import styles from "./home.module.css";
import FooterN from "./Components/FooterN/Footer";

export default function Home() {
  const messages = ["QR Codes!", "NFC Cards!"];
  const features = [
    "Website",
    "Text",
    "PDF",
    "Visiting Card",
    "WhatsApp",
    "Media",
    "Location",
    "UPI"
  ];
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [index, setIndex] = useState(0);
  const [currentFeature, setCurrentFeature] = useState(messages[0]);
  const [qrFeatureIndex, setQrFeatureIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("qr");

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fading out

      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setCurrentMessage(messages[index]);
        setQrFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
        setCurrentFeature(features[qrFeatureIndex]);
        setFade(false); // Start fading in
      }, 500); // Match this duration with the CSS fade-out duration
    }, 1500); // Change text every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
    // eslint-disable-next-line
  }, [index, qrFeatureIndex]);

  return (
    <>
      <Navbar />
      <div className={styles.homepage}>
        <div className={styles.banner}>
          <Container>
            <div className={styles.banner_text}>
              <h1>WELCOME!</h1>
              <h2>
                Transform your business with our suite of powerful{" "}
                <span
                  className={`${styles.text_rotator} ${
                    fade ? styles.fade_out : styles.fade_in
                  }`}
                >
                  {currentMessage}
                </span>
              </h2>
            </div>
          </Container>
        </div>

        <div className={styles.personalized_container}>
          <Container>
            <div className={styles.personalized_box}>
              <div className={styles.products}>
                <div
                  className={
                    selectedProduct === "qr" && styles.selected_product
                  }
                  onClick={() => setSelectedProduct("qr")}
                >
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M43.3333 46.6667V43.3333H33.3333V33.3333H43.3333V40H50V46.6667H46.6667V53.3333H40V60H33.3333V50H40V46.6667H43.3333ZM60 60H46.6667V53.3333H53.3333V46.6667H60V60ZM0 0H26.6667V26.6667H0V0ZM6.66667 6.66667V20H20V6.66667H6.66667ZM33.3333 0H60V26.6667H33.3333V0ZM40 6.66667V20H53.3333V6.66667H40ZM0 33.3333H26.6667V60H0V33.3333ZM6.66667 40V53.3333H20V40H6.66667ZM50 33.3333H60V40H50V33.3333ZM10 10H16.6667V16.6667H10V10ZM10 43.3333H16.6667V50H10V43.3333ZM43.3333 10H50V16.6667H43.3333V10Z"
                      fill="#047AB2"
                    />
                  </svg>
                </div>
                <div
                  className={
                    selectedProduct === "nfc" && styles.selected_product
                  }
                  onClick={() => setSelectedProduct("nfc")}
                >
                  <svg
                    width="70"
                    height="70"
                    viewBox="0 0 70 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 0H66.5C68.4331 0 70 1.74109 70 3.88889V66.1111C70 68.2589 68.4331 70 66.5 70H3.5C1.56702 70 0 68.2589 0 66.1111V3.88889C0 1.74109 1.56702 0 3.5 0ZM63 31.1111H6.99999V62.2222H63V31.1111ZM63 23.3333V7.77777H6.99999V23.3333H63ZM42 46.6667H56V54.4445H42V46.6667Z"
                      fill="#047AB2"
                    />
                  </svg>
                </div>
              </div>
              {selectedProduct === "qr" && (
                <div>
                  <div className={styles.personalized_heading}>
                    <p>
                      Create personalized QR Codes with features like{" "}
                      <span>{currentFeature}</span>
                    </p>
                  </div>
                  <center>
                    <br />
                    <img src="./images/personalized-qr.gif" alt="" />
                  </center>
                </div>
              )}
              {selectedProduct === "nfc" && (
                <div className={styles.personalized_gif}>
                  <img src="./images/personalized-nfc.gif" alt="" />
                </div>
              )}
            </div>
          </Container>
        </div>

        <div className={styles.our_specialization_container}>
          <Container>
            <center>
              <div className={styles.our_products_heading}>
                <p>
                  At our company, we specialize in developing powerful Products
                  that help business of all sizes streamline their operations,
                  increase productivity, and boost revenue
                </p>
              </div>
            </center>
            <div className={styles.qr_specialization}>
              <div>
                <img src="./images/qr_specialization.png" alt="" />
              </div>
              <div>
                <p style={{lineHeight:"40px" , fontSize:"25px", color:"#004367", fontWeight:"600"}}>
                <h3>Overview</h3>  
                  <p style={{fontWeight:"500", fontSize:"16px"}}>Welcome to Scanonn, your go-to platform for creating versatile and customizable QR codes with ease. Our intuitive QR code generator empowers individuals and businesses to seamlessly connect their audience to digital content, boosting engagement and accessibility.</p>
                
                <h3>Why Choose Us</h3>
                  <p style={{fontWeight:"500", fontSize:"16px"}}>
                  <ul>
                    <li><b>Customizable Designs:</b></li>
                    <li><b>Personalize your QR codes</b> with colors, logos, and patterns to align with your brand identity.</li>
                    <li><b>Multiple Content Types:</b> Create QR codes for websites, text, emails, Wi-Fi access, location sharing, contact information, and more.</li>
                    <li><b>User-Friendly Interface:</b> Generate QR codes in just a few clicks—no technical expertise required.</li>
                    <li><b>High-Resolution Output:</b> Download your QR codes in high-quality formats suitable for printing and digital use.</li>
                    <li><b>Analytics Integration (optional):</b> Track the performance of your QR codes with scan analytics for data-driven decisions.</li>
                  </ul>
                  </p>
                </p>
                <Button variant="primary">Explore more</Button>
              </div>
            </div>
            <div className={styles.nfc_specialization}>
              <div>
                <p style={{lineHeight:"40px" , fontSize:"25px", color:"#004367"}}>
                <h3>Overview</h3> 
                <p style={{fontWeight:"500", fontSize:"16px"}}>Near Field Communication (NFC) technology takes convenience a step further. Our NFC-enabled cards allow for effortless data sharing, simply by tapping the card on a compatible device. From payment systems to smart access, NFC is revolutionizing how people interact with the world.</p>
                
                <h3>Why Choose Us</h3>
                  <p style={{fontWeight:"500", fontSize:"16px"}}>
                    <p>At NFC Solutions, we go beyond just providing NFC cards. We deliver a holistic experience that includes:</p>
                    <span><b>1. Innovative Technology</b></span>
                    <p>We stay ahead of the curve by adopting the latest NFC advancements, ensuring your cards are equipped with cutting-edge features.</p>
                    <span><b>2. Exceptional Craftsmanship</b></span>
                    <p>Every card is crafted with precision and attention to detail, ensuring a product that is both functional and aesthetically pleasing.</p>
                    <span><b>3. Dedicated Support</b></span>
                    <p>Our team is here to guide you every step of the way—from design to implementation—ensuring your NFC card exceeds your expectations.</p>
                    <span><b>4. Affordable Pricing</b></span>
                    <p>We offer competitive pricing without compromising on quality. Whether you're an individual or a business, we have solutions to fit your budget.</p>
                  </p>
                </p>
                <Button variant="primary">Explore more</Button>
              </div>
              <div>
                <img src="./images/nfc_specialization.png" alt="" />
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.qr_product_container}>
          <Container>
            <center>
              <div className={styles.our_products_heading}>
                <p>
                  Get the most out of our <span>products</span>
                </p>
              </div>
            </center>
            <div className={styles.qr_product_box}>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                "Use Cases: QR Codes Can Transform Your Business"
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  QR codes offer endless possibilities for businesses to connect, share, and engage. Here's how you can leverage QR codes for various purposes:
                  <p>
                    <h4>Marketing Campaigns</h4>
                    Incorporate QR codes into your marketing campaigns to drive traffic and boost conversions. Place QR codes on posters, brochures, and billboards that link to:
                    <ul>
                      <li>Landing pages</li>
                      <li>Special offers</li>
                      <li>Video content</li>
                      <li>Product pages</li>
                    </ul>
                  </p>
                  <li>
                    <h4>Business Cards</h4>
                    Upgrade your business card by adding a vCard QR code. Instantly share contact details, including name, email, phone number, and company website.
                  </li>
                  <li>
                  <h4>Product Packaging</h4>
                    Enhance customer experience by adding QR codes to product packaging. Provide:
                    <ul>
                      <li>User manuals</li>
                      <li>How-to videos</li>
                      <li>Warranty registration</li>
                      <li>Feedback forms</li>
                    </ul>
                  </li>
                  <li>
                    <h4>Events & Promotions</h4>
                      Simplify event registrations, ticketing, and promotions with QR codes. Link to event details, RSVP pages, or promotional discounts for seamless interaction.
                  </li>
                  <li>
                      <h4>Restaurant Menus</h4>
                      Digitize your restaurant or café menu with QR codes. Customers can scan, browse, and place orders directly from their smartphones.
                  </li>
                  <li>
                    <h4>Education & Training</h4>
                        Share learning materials, training videos, or digital resources through QR codes. Enhance engagement for students and employees with easy access to content.
                  </li>
                </ul>
                <div style={{marginTop: "16px"}}>
                    <Button variant="primary">Explore more</Button>
                </div>
              </div>
              <div>
                <img src="./images/qr_product.png" alt="" />
                <br />
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.nfc_product_container}>
          <Container>
            <div className={styles.nfc_product_box}>
              <div>
                <img src="./images/nfc_product.png" alt="" />
                <br />
                <Button style={{ marginLeft: "50px" }} variant="primary">
                  Explore more
                </Button>
              </div>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "30px" }}>
                  "Use Cases: NFC Cards for Everyone"
                </p>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    margin: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <li>
                    <h4>Business Networking Made Easy</h4> 
                    Forget carrying stacks of paper business cards. With an NFC-enabled business card: Share your contact details instantly 
                    with a tap Direct others to your website,portfolio, or LinkedIn profile Leave a lasting impression with a high-tech solution
                  </li>
                  <li>
                    <h4>Marketing and Promotions</h4>
                    Boost your marketing efforts with interactive NFC cards that: Link to promotional videos or product catalogs Offer discounts and rewards to customers Provide instant access to event tickets or digital content
                  </li>
                  <li>
                    <h4>Streamlined Payments</h4>
                    Enable cashless, contactless payments with NFC cards that work seamlessly with payment gateways. Whether you're running a retail store, food truck, or freelance business, NFC cards make transactions effortless.
                  </li>
                  <li>
                    <h4>Access Control and Security</h4>
                    NFC cards can act as digital keys, offering secure access to:
                    <li>
                      Office buildings
                      Hotel rooms
                      Smart devices
                    </li>
                  </li>
                  <li>
                    <h4>Personal Use and Social Sharing</h4>
                      Use NFC cards for personal purposes, such as:
                    <li>
                      Sharing your social media profiles
                      Connecting with others at events
                      Directing people to your blog or YouTube channel
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.company_trust_container}>
          <Container>
            <div className={styles.company_trust_box}>
              <div className={styles.company_trust_heading}>
                <p>
                  Companies that <span>trust</span> us
                </p>
              </div>
              <div className={styles.company_trust}>
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
                <img src="./images/company1.png" alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.testimonials_container}>
          <Container>
            <div className={styles.testimonial_oval}></div>
            <div className={styles.testimonials_box}>
              <div className={styles.testimonial_heading}>
                <p>
                  No matter which product you choose, you can count on our team
                  of experts to provide top-notch support and help you get the
                  most out of from us!
                </p>
                <div className={styles.pink_dot}></div>
              </div>
              <div className={styles.testimonials}>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "ScanOnn's QR solutions have transformed our restaurant
                    operations. Customers love the seamless experience, and we
                    appreciate the customization options that keep our brand
                    unique!"
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>John D.</p>
                  <span>Owner, The Gourmet Kitchen </span>
                </div>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "Our students and parents love the interactive experience
                    with ScanOnn's NFC cards. It has allowed us to simplify
                    information sharing and track engagement effectively."
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>P.Vallai</p>
                  <span>Marketing Head, EduTech Innovations</span>
                </div>
                <div className={styles.testimonial}>
                  <div className={styles.stars}>
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </div>
                  <p className={styles.testimonial_text}>
                    "With ScanOnn's QR codes, we’ve taken our marketing
                    campaigns to the next level. Easy to create, customize, and
                    use—they’ve helped us boost engagement and create lasting
                    impressions."
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  </div>
                  <p className={styles.testimonial_name}>Rajesh K.</p>
                  <span>Director, EcoPrint Solutions </span>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.testimonial_background}>
          <img src="./images/objects.png" alt="" />
          <img src="./images/oval.png" alt="" />
        </div>
      </div>
      <FooterN />
    </>
  );
}
