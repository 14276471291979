import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import FooterN from "./Components/FooterN/Footer";
import style from './term-conditions.module.css'

export default function TermCondition() {
  return (
    <>
      <Navbar />
    <div className={style["term-condition-body"]}>
        <div className={style["term-conditon-content"]}>
            <h1>Terms and Conditions</h1>
            <p>Welcome to our website! By using this website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using this website, you accept and agree to be bound by the terms and conditions of this agreement. In addition, when using this website's particular services, you shall be subject to any posted guidelines or rules applicable to such services.</p>

            <h2>2. Modification of Terms</h2>
            <p>We reserve the right to change these terms at any time. It is your responsibility to check this page periodically for updates. Your continued use of the website after changes are posted constitutes your acceptance of the revised terms.</p>

            <h2>3. Use of the Website</h2>
            <p>You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.</p>

            <h2>4. Privacy Policy</h2>
            <p>Your use of the website is also governed by our Privacy Policy, which you can access on a separate page.</p>

            <h2>5. Limitation of Liability</h2>
            <p>We are not responsible for any damages arising out of your use of the website. This includes direct, indirect, incidental, punitive, and consequential damages.</p>

            <h2>6. Governing Law</h2>
            <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
        </div>
    </div>
<FooterN />
</>
  );
}
