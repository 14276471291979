import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import VcardDetails from "./QRDetails/VcardDetails";
import Redirect from "./QRDetails/Redirect";
import WhatsappMessage from "./QRDetails/WhatsappMessage";
import TextDetails from "./QRDetails/TextDetails";
import MediaDetails from "./QRDetails/MediaDetails";
import PDFDetails from "./QRDetails/PDFDetails";
import LocationDetails from "./QRDetails/LocationDetails";
import UPIDetails from "./QRDetails/UPIDetails";
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import ContactUs from './ContactUs';
import TermCondition from  './TermCondition';
import CancellationPolicy from './CancellationPolicy'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/term-and-condition" element={<TermCondition />} />

        {/* ========== Qr detail pages ========== */}
        <Route path="/vcard-details/:string" element={<VcardDetails />} />
        <Route path="/redirect/:string" element={<Redirect />} />
        <Route path="/whatsapp-message/:string" element={<WhatsappMessage />} />
        <Route path="/text-details/:string" element={<TextDetails />} />
        <Route path="/media-details/:string" element={<MediaDetails />} />
        <Route path="/pdf-details/:string" element={<PDFDetails />} />
        <Route path="/location-details/:string" element={<LocationDetails />} />
        <Route path="/upi-details/:string" element={<UPIDetails />} />
      </Routes>
    </Router>
  );
}
