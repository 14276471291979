import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import FooterN from "./Components/FooterN/Footer";
import style from './cancellation.module.css';


export default function CancellationPolicy() {
  return (
    <>
      <Navbar />
    <div className={style["cancellation-body"]}>
        <div className={style["cancelllation-content"]}>
            <h1>Cancellation Policy</h1>
            <p>We understand that sometimes plans change, and you may need to cancel your service or order. Please review the following policies regarding cancellations:</p>

            <h2>1. Cancellation Timeframe</h2>
            <p>You can cancel your service or order up to 24 hours before the scheduled time to receive a full refund. Cancellations made less than 24 hours in advance may not be eligible for a refund.</p>

            <h2>2. How to Cancel</h2>
            <p>To cancel your order or service, please contact our support team through the following channels:</p>
            <ul>
                <li>Email: support@scanonn.com</li>
            </ul>

            <h2>3. Refund Process</h2>
            <p>If eligible, refunds will be processed within 5-7 business days. Refunds will be issued to the original payment method used at the time of purchase.</p>

            <h2>4. Exceptions</h2>
            <p>Certain services or orders may not be eligible for cancellation or refund. Please refer to the specific terms provided at the time of purchase.</p>

            <h2>5. Changes to this Policy</h2>
            <p>We reserve the right to modify this cancellation policy at any time. Changes will be effective immediately upon posting to this page.</p>
        </div>
    </div>
    <FooterN />
</>
  );
}
